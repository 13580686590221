html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  height: 100%; }

body {
  position: relative;
  height: 100%;
  min-width: 320px;
  color: #000;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-scroll-behavior: smooth;
  -o-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden; }

.layout-content {
  width: 1300px;
  margin: auto;
  padding: 0 30px;
  box-sizing: border-box; }
  @media only screen and (max-width: 1300px) {
    .layout-content {
      width: 100%;
      padding: 0 15px; } }

.header {
  padding: 30px 0 10px 0;
  background-color: #3B2050;
  z-index: 1; }
  .header__logo {
    width: 500px;
    margin: auto; }
    @media only screen and (max-width: 760px) {
      .header__logo {
        width: 100%; } }
  .header__logo-image {
    width: 100%; }

.preview-result {
  position: relative;
  padding: 100px 0 0 0;
  z-index: 10; }
  @media only screen and (max-width: 760px) {
    .preview-result {
      padding: 50px 0 0 0; } }
  .preview-result__item {
    position: relative;
    margin-bottom: 100px;
    padding-bottom: 60px; }
    @media only screen and (max-width: 760px) {
      .preview-result__item {
        margin-bottom: 60px; } }
    .preview-result__item::after {
      content: '';
      display: table;
      clear: both; }
    .preview-result__item--left .preview-result__cover {
      float: left; }
      @media only screen and (max-width: 760px) {
        .preview-result__item--left .preview-result__cover {
          float: none; } }
    .preview-result__item--left .preview-result__parameters {
      float: right;
      padding-left: 30px; }
      @media only screen and (max-width: 760px) {
        .preview-result__item--left .preview-result__parameters {
          float: none;
          padding-left: 0; } }
    .preview-result__item--left .preview-result__indicators {
      float: right;
      padding-left: 30px;
      text-align: left; }
      @media only screen and (max-width: 1000px) {
        .preview-result__item--left .preview-result__indicators {
          float: none;
          padding-left: 0; } }
    .preview-result__item--left .preview-result__price {
      left: 20px; }
    .preview-result__item--right {
      text-align: right; }
      @media only screen and (max-width: 760px) {
        .preview-result__item--right {
          text-align: left; } }
      .preview-result__item--right .preview-result__cover {
        float: right; }
        @media only screen and (max-width: 760px) {
          .preview-result__item--right .preview-result__cover {
            float: none; } }
      .preview-result__item--right .preview-result__parameters {
        float: left;
        padding-right: 30px; }
        @media only screen and (max-width: 760px) {
          .preview-result__item--right .preview-result__parameters {
            float: none;
            padding-right: 0; } }
      .preview-result__item--right .preview-result__indicators {
        float: left;
        padding-right: 30px;
        text-align: right; }
        @media only screen and (max-width: 1000px) {
          .preview-result__item--right .preview-result__indicators {
            float: none;
            padding-right: 0;
            text-align: left; } }
      .preview-result__item--right .preview-result__price {
        right: 20px; }
        @media only screen and (max-width: 760px) {
          .preview-result__item--right .preview-result__price {
            right: auto;
            left: 20px; } }
  .preview-result__cover {
    display: block;
    position: relative;
    width: 430px;
    height: 310px;
    text-decoration: none; }
    @media only screen and (max-width: 1000px) {
      .preview-result__cover {
        margin-bottom: 20px;
        width: 380px;
        height: 260px; } }
    @media only screen and (max-width: 760px) {
      .preview-result__cover {
        width: 100%;
        height: 450px; } }
    @media only screen and (max-width: 600px) {
      .preview-result__cover {
        height: 350px; } }
    @media only screen and (max-width: 500px) {
      .preview-result__cover {
        height: 230px; } }
  .preview-result__price {
    display: block;
    position: absolute;
    top: 20px;
    padding: 7px 10px;
    color: #fff;
    background-color: #3B2050;
    font-weight: bold; }
  .preview-result__currency {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    font-size: 16px; }
  .preview-result__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .preview-result__parameters {
    width: calc(100% - 430px);
    box-sizing: border-box; }
    @media only screen and (max-width: 1000px) {
      .preview-result__parameters {
        width: calc(100% - 380px); } }
    @media only screen and (max-width: 760px) {
      .preview-result__parameters {
        width: 100%; } }
  .preview-result__description {
    position: relative;
    margin-bottom: 10px;
    padding: 15px 0;
    color: #666;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: 300;
    line-height: 1.2;
    box-sizing: border-box; }
  .preview-result__title {
    margin-bottom: 10px;
    text-decoration: none;
    color: #3B2050;
    font-size: 28px;
    font-family: 'Roboto Condensed', sans-serif; }
  .preview-result__indicators {
    width: calc(100% - 430px);
    padding-top: 20px;
    text-align: left;
    box-sizing: border-box;
    border-top: 1px solid #ccc; }
    @media only screen and (max-width: 1000px) {
      .preview-result__indicators {
        float: none;
        width: 100%;
        clear: both; } }
    .preview-result__indicators::after {
      content: '';
      display: table;
      clear: both; }
    .preview-result__indicators li {
      display: inline-block;
      vertical-align: top;
      width: 45%;
      margin-bottom: 15px;
      text-align: left;
      font-size: 16px; }
      @media only screen and (max-width: 760px) {
        .preview-result__indicators li {
          display: block;
          width: 100%; } }
      .preview-result__indicators li::after {
        content: '';
        display: table;
        clear: both; }
      .preview-result__indicators li .label {
        float: left;
        width: 35%;
        padding-right: 10px;
        color: #666;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        box-sizing: border-box; }
        @media only screen and (max-width: 760px) {
          .preview-result__indicators li .label {
            width: 40%; } }
      .preview-result__indicators li .value {
        float: left;
        width: 65%;
        padding-right: 10px;
        font-weight: 300;
        box-sizing: border-box; }
        @media only screen and (max-width: 760px) {
          .preview-result__indicators li .value {
            width: 60%; } }
  .preview-result__more-link {
    display: block;
    position: absolute;
    left: calc(50% - 75px);
    bottom: 0;
    width: 150px;
    margin: 30px auto 0;
    padding: 10px 0;
    text-decoration: none;
    text-align: center;
    color: #3B2050;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    border-bottom: 2px solid #3B2050;
    transition: all 0.3s ease;
    z-index: 1; }
    .preview-result__more-link::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #3B2050;
      transition: all 0.3s ease;
      z-index: -1; }
    .preview-result__more-link:hover {
      color: #fff; }
      .preview-result__more-link:hover::before {
        height: 100%; }

.footer {
  padding: 20px 0;
  text-align: center;
  color: #e5e5e5;
  background-color: #3B2050;
  font-size: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300; }
  .footer img {
    display: inline-block;
    vertical-align: middle;
    width: 150px; }
  .footer span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 13px;
    padding-top: 3px;
    font-size: 17px; }
    .footer span:last-child {
      margin-right: 0; }
