@import "../../node_modules/sass-mediaqueries/media-queries";

@import "reset";
@import "variables";

html {
  height: 100%; }

body {
  position: relative;
  height: 100%;
  min-width: 320px;
  color: #000;
  background-color: #fff;
  text-size-adjust: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-scroll-behavior: smooth;
  -o-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden; }

.layout-content {
  width: 1300px;
  margin: auto;
  padding: 0 30px;
  box-sizing: border-box;

  @include max-screen(1300px) {
    width: 100%;
    padding: 0 15px; } }


@import "header";
@import "preview-result";
@import "footer";
