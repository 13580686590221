.header {
  padding: 30px 0 10px 0;
  background-color: $color-violet;
  z-index: 1;

  &__logo {
    width: 500px;
    margin: auto;

    @include max-screen(760px) {
      width: 100%; } }

  &__logo-image {
    width: 100%; } }
