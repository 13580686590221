.preview-result {
  position: relative;
  padding: 100px 0 0 0;
  z-index: 10;

  @include max-screen(760px) {
    padding: 50px 0 0 0; }

  &__item {
    position: relative;
    margin-bottom: 100px;
    padding-bottom: 60px;

    @include max-screen(760px) {
      margin-bottom: 60px; }

    &::after {
      content: '';
      display: table;
      clear: both; }

    &--left {
      .preview-result__cover {
        float: left;

        @include max-screen(760px) {
          float: none; } }

      .preview-result__parameters {
        float: right;
        padding-left: 30px;

        @include max-screen(760px) {
          float: none;
          padding-left: 0; } }

      .preview-result__indicators {
        float: right;
        padding-left: 30px;
        text-align: left;

        @include max-screen(1000px) {
          float: none;
          padding-left: 0; } }

      .preview-result__price {
        left: 20px; } }

    &--right {
      text-align: right;
      @include max-screen(760px) {
        text-align: left; }

      .preview-result__cover {
        float: right;

        @include max-screen(760px) {
          float: none; } }

      .preview-result__parameters {
        float: left;
        padding-right: 30px;

        @include max-screen(760px) {
          float: none;
          padding-right: 0; } }

      .preview-result__indicators {
        float: left;
        padding-right: 30px;
        text-align: right;

        @include max-screen(1000px) {
          float: none;
          padding-right: 0;
          text-align: left; } }

      .preview-result__price {
        right: 20px;

        @include max-screen(760px) {
          right: auto;
          left: 20px; } } } }


  &__cover {
    display: block;
    position: relative;
    width: 430px;
    height: 310px;
    text-decoration: none;

    @include max-screen(1000px) {
      margin-bottom: 20px;
      width: 380px;
      height: 260px; }

    @include max-screen(760px) {
      width: 100%;
      height: 450px; }

    @include max-screen(600px) {
      height: 350px; }

    @include max-screen(500px) {
      height: 230px; } }

  &__price {
    display: block;
    position: absolute;
    top: 20px;
    padding: 7px 10px;
    color: #fff;
    background-color: $color-violet;
    font-weight: bold; }

  &__currency {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    font-size: 16px; }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

  &__parameters {
    width: calc(100% - 430px);
    box-sizing: border-box;

    @include max-screen(1000px) {
      width: calc(100% - 380px); }

    @include max-screen(760px) {
      width: 100%; } }

  &__description {
    position: relative;
    margin-bottom: 10px;
    padding: 15px 0;
    color: #666;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: 300;
    line-height: 1.2;
    box-sizing: border-box; }

  &__title {
    margin-bottom: 10px;
    //padding-top: 15px
    text-decoration: none;
    color: $color-violet;
    font-size: 28px;
    font-family: 'Roboto Condensed', sans-serif; }

  &__indicators {
    width: calc(100% - 430px);
    padding-top: 20px;
    text-align: left;
    box-sizing: border-box;
    border-top: 1px solid #ccc;

    @include max-screen(1000px) {
      float: none;
      width: 100%;
      clear: both; }

    &::after {
      content: '';
      display: table;
      clear: both; }

    li {
      display: inline-block;
      vertical-align: top;
      width: 45%;
      margin-bottom: 15px;
      text-align: left;
      font-size: 16px;

      @include max-screen(760px) {
        display: block;
        width: 100%; }

      &::after {
        content: '';
        display: table;
        clear: both; }

      .label {
        float: left;
        width: 35%;
        padding-right: 10px;
        color: #666;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        box-sizing: border-box;

        @include max-screen(760px) {
          width: 40%; } }

      .value {
        float: left;
        width: 65%;
        padding-right: 10px;
        font-weight: 300;
        box-sizing: border-box;

        @include max-screen(760px) {
          width: 60%; } } } }

  &__more-link {
    display: block;
    position: absolute;
    left: calc(50% - 75px);
    bottom: 0;
    width: 150px;
    margin: 30px auto 0;
    padding: 10px 0;
    text-decoration: none;
    text-align: center;
    color: $color-violet;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    border-bottom: 2px solid $color-violet;
    transition: all 0.3s ease;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: $color-violet;
      transition: all 0.3s ease;
      z-index: -1; }

    &:hover {
      color: #fff;

      &::before {
        height: 100%; } } } }










