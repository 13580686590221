.footer {
  padding: 20px 0;
  text-align: center;
  color: #e5e5e5;
  background-color: $color-violet;
  font-size: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;

  img {
    display: inline-block;
    vertical-align: middle;
    width: 150px; }

  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 13px;
    padding-top: 3px;
    font-size: 17px;

    &:last-child {
      margin-right: 0; } } }
